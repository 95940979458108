@import 'bower_components/fontawesome/scss/variables';
@import 'bower_components/fontawesome/scss/path';
@import 'bower_components/fontawesome/scss/mixins';
@import 'bower_components/fontawesome/scss/icons';

@import 'addons/ember-calendar/paint-core';
@import 'addons/ember-calendar/main';

@media (min-width: 1200px) {
  .menu-container {
    min-width: 940px;
  }
}
.white {
  color: white
}

div.carpets a.active {
  color: #96dae4 !important;
}
div.carpets span.disabled-carpet {
  color: #6c757d !important;
  opacity: 0.3;
}
.p100 {
  padding: 100px;
}
.modal-content {
  width: 900px !important
}
.modal-backdrop {
  opacity: 0.6;
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000
}

.featured-img {
  position: absolute;
  right: -120px;
  bottom: -1150px;
}

.as-calendar {
  background: transparent !important;
}

/*============== NOTIFICATION ===============*/

#gritter-notice-wrapper {
  position: fixed;
  right: 20px;
  top: 82px;
  max-width: 620px;
  min-width: 320px;
  z-index: 9999;
}

.gritter-item-wrapper {
  margin: 0 0 10px;
  position: relative;
}

.gritter-img-container i {
  font-size: 33px;
}

.gritter-item-wrapper {
  background: rgba(0, 0, 0, 0) url("img/gritter-bg.png") repeat scroll 0 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.alert {
  border: none;
  padding: 0.75rem 1.25rem;
}

#gritter-notice-wrapper .gritter-item-wrapper.alert {
  padding:0;
  border-radius: 5px;
  -webkit-box-shadow: 11px 10px 23px -5px rgba(0,0,0,0.77);
  -moz-box-shadow: 11px 10px 23px -5px rgba(0,0,0,0.77);
  box-shadow: 11px 10px 23px -5px rgba(0,0,0,0.77);
}