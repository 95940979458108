.as-calendar-time-zone-select {
  position: relative;

  &.open {
    .rl-dropdown-toggle i {
      transform: rotate(180deg);
    }
  }

  .rl-dropdown-toggle {
    background-color: transparent;
    border: 0;
    font-size: $base-font-size;
    margin: 0;
    padding: 0;

    &:hover {
      color: color(link);
    }

    i {
      @include icon(caret-down);

      transition: transform 200ms ease;
    }

    span {
      font-weight: $font-weight-bold;
    }
  }

  .rl-dropdown {
    background-clip: padding-box;
    background-color: dropdown-settings(background-color);
    border: 1px solid dropdown-settings(border-color);
    border-radius: $global-radius / 2;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .175);
    display: none;
    float: left;
    font-size: $small-font-size;
    left: 0;
    list-style: none;
    margin: 0;
    min-width: 200px;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: dropdown-settings(z-index);

    .search {
      cursor: auto;
      padding: dropdown-settings(search, padding-inner) dropdown-settings(search, padding-inner) * 2;
      position: relative;

      &:hover {
        background-color: transparent;
      }

      .icon {
        height: dropdown-settings(search, icon-size);
        left: dropdown-settings(search, padding-inner);
        margin: dropdown-settings(search, padding-inner) * 2 0;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: dropdown-settings(search, padding-inner);
        width: dropdown-settings(search, icon-size);

        i {
          @include icon(search);

          color: color(primary);
          line-height: dropdown-settings(search, icon-size);
        }
      }

      input {
        @include form-input;

        height: dropdown-settings(search, icon-size);
        line-height: dropdown-settings(search, icon-size);
        margin: dropdown-settings(search, padding-inner) * 2 0;
        padding-left: dropdown-settings(search, icon-size);
      }
    }

    .results {
      margin: 0;
      max-height: dropdown-settings(search, results-height);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;

      > ul {
        margin: 0;
        padding: 0;

        > li {
          cursor: pointer;
          padding: dropdown-settings(search, padding-inner) $column-gutter / 2;

          &:hover {
            background-color: dropdown-settings(link, hover-background-color);
          }
        }
      }
    }
  }
}
